@charset "UTF-8";

:root {
    --background-color: #f8fafc;
    --white: #ecf3fe;
    --navy: #1c3d6e;
    --grey: grey;
    --inverse: black;
    --text: #040911;
    --lightGrey: #ccc;
    --navHover: #282828;
    --reallyLightShadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    --lightShadow: 0 4px 8px 2px rgba(27, 60, 109, 0.3);
    --heavyShadow: 0 4px 8px 0 rgba(27, 60, 109, 0.5);
}

[data-theme='dark'] {
    --background-color: #040911;
    --grey: #b0b0b0;
    --inverse: #e5e5e5;
    --lightGrey: #7a7a7a;
    --navHover: #333333;
    --text: #e2ebf8;
}

* {
    box-sizing: border-box;
    transition: background-color 0.2s;
}

body {
    background-color: var(--background-color);
    font-family: 'Roboto', sans-serif;
    padding-top: 20px;
    margin: 0;
    width: 100%;
    overflow-x: hidden;
    color: var(--text);
}

h1 {
    color: var(--navy);
    text-align: center;
    font-family: 'Montserrat', sans-serif;
}

h2 {
    color: var(--navy);
    font-family: 'Montserrat', sans-serif;
}

h4 {
    color: var(--grey);
}

h5 {
    color: var(--grey);
    font-family: 'Roboto', sans-serif;
}

p {
    font-size: 20px;
}

li {
    font-size: 20px;
}

a:link,
a:visited {
    text-decoration: none;
}

.textBlurb {
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
}

.page {
    padding-bottom: 30px;
    margin-left: 20px;
    margin-right: 20px;
}

footer {
    background-color: var(--navy);
    color: var(--white);
    padding: 15px 0;
    text-align: center;
}

.socialMediaContainer {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.socialMediaLink {
    text-decoration: none;
    color: var(--white);
    display: flex;
    align-items: center;
    gap: 5px;
}

.socialMediaText {
    font-size: 16px;
    margin: 0;
}

.logoBanner {
    background-color: var(--navy);
    padding-top: 50px;
    padding-bottom: 10px;
    margin-left: -20px;
    margin-right: -20px;
}

.logoBanner h1 {
    color: var(--white);
}

#bannerLogo {
    width: 200px;
}

#DiscordWidget {
    margin: auto;
    float: right;
}

#AboutUs {
    width: calc(100% - 374.73px);
    min-height: 500px;
}

#Projects {
    margin-top: 100px;
    justify-content: center;
    align-items: center;
}

.sponsorship {
    margin-top: 50px;
    width: 100%;
    overflow-x: hidden;
}

.sponsorimgs {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.sponsor {
    max-width: 100%;
    width: auto;
    height: 100px;
    margin: 20px;
}

#RIOTlogo {
    -webkit-transition: -webkit-transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out;
}

#SignUp {
    background-color: var(--navy);
    color: var(--white);
    text-decoration: none;
    text-align: center;
    padding-top: 12px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    float: center;
    width: 225px;
    height: auto;

    resize: horizontal;
    border-radius: 10px;
    transition: all 0.5s;
}

#SignUp:hover {
    box-shadow:
        0 12px 16px 0 rgba(0, 0, 0, 0.24),
        0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

#SignUpUnavailable {
    background-color: var(--grey);
    color: var(--white);
    text-decoration: none;
    text-align: center;
    padding-top: 12px;
    padding-bottom: 10px;
    float: center;
    width: 300px;
    height: auto;
    resize: horizontal;
    border-radius: 10px;
}

#DonateButton {
    background-color: var(--navy);
    color: var(--white);
    text-decoration: none;
    text-align: center;
    padding-top: 12px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    float: center;
    width: 450px;
    resize: horizontal;
    border-radius: 10px;
    transition: all 0.5s;
}

#DonateButton:hover {
    box-shadow:
        0 12px 16px 0 rgba(0, 0, 0, 0.24),
        0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.default-img {
    height: auto;
    max-width: 800px;
    width: 100%;
    border-radius: 10px;
}

/*--------------------------------------*/
/*------------Image Carousel------------*/
/*--------------------------------------*/
.slideshow-container {
    max-width: 1000px;
    position: relative;
    margin: auto;
}

.Containers {
    display: none;
}

.innerSlide {
    background-color: var(--navy);
    color: var(--white);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}
#carouselContent img {
    max-height: 450px;
    height: 100%;
    width: 100%;
    object-fit: contain;
    margin-top: 10px;
    padding: 25px;
}
#carouselContent p {
    font-size: 20px;
    margin: 0;
    padding: 15px;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#carousel-button {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    transition: ease 0.3s;
}

#carousel-button:hover {
    color: var(--navy);
    transition: ease 0.3s;
}

/* Specific to left arrow */
#carousel-button:nth-child(1) {
    left: -50px;
}
#carousel-button:nth-child(1):hover {
    transform: translate(-5px, -50%) scale(1.1);
}

/* Specific to right arrow */
#carousel-button:nth-child(3) {
    right: -50px;
}

#carousel-button:nth-child(3):hover {
    transform: translate(5px, -50%) scale(1.1);
}

.Back,
.forward {
    cursor: pointer;
    position: absolute;
    top: 48%;
    width: auto;
    margin-top: -23px;
    padding: 17px;
    color: var(--white);
    font-weight: bold;
    font-size: 19px;
    transition: 0.4s ease;
    border-radius: 0 5px 5px 0;
    user-select: none;
    background-color: var(--navy);
}

.forward {
    right: 0;
    border-radius: 4px 0 0 4px;
}

.Info {
    color: var(--white);
    font-size: 16px;
    padding: 10px 14px;
    position: relative;
    height: 200px;
    bottom: 10px;
    width: 100%;
    text-align: center;
    background-color: var(--navy);
}

.dots {
    cursor: pointer;
    height: 16px;
    width: 16px;
    margin: 0 3px;
    background-color: var(--lightGrey);
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.5s ease;
}

.enable,
.dots:hover {
    background-color: var(--navy);
}

.fade {
    -webkit-animation-name: fade;
    -webkit-animation-duration: 1.4s;
    animation-name: fade;
    animation-duration: 1.4s;
}

@-webkit-keyframes fade {
    from {
        opacity: 0.5;
    }

    to {
        opacity: 2;
    }
}

@keyframes fade {
    from {
        opacity: 0.5;
    }

    to {
        opacity: 2;
    }
}

/*---------------------------------------*/
/*----------------NAVBAR-----------------*/
/*---------------------------------------*/
nav {
    background-color: var(--navy);
    text-align: left;
    font-size: 16px;
    position: fixed;
    z-index: 1100;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
}

nav a {
    color: var(--white);
    display: inline-block;
    padding: 10px;
    margin: 10px;
    border-radius: 25px;
}

nav a:hover,
nav a.active {
    background-color: var(--background-color);
    color: var(--text);
}

#darkMode {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    height: 100%;
    width: 150px;
    color: var(--white);
    background-color: var(--navy);
    float: right;
    cursor: pointer;
    margin-top: 13px;
    margin-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
}

#darkModeIcon {
    margin-left: 6px;
}

/*---------------------------------------*/
/*----------------MOBILE NAVBAR----------*/
/*---------------------------------------*/

.MobileDarkModeButton {
    cursor: pointer;
    margin-right: 32px;
}

.navbar-spacer {
    height: 60px;
}
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--navy);
    position: fixed;
    z-index: 100;
    top: 0;
    width: 100%;
    height: 80px;
}

.menu-bars {
    display: inline-block;
    margin-left: 32px;
    font-size: 2rem;
    background: none;
}

.nav-menu {
    background-color: var(--navy);
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
}

.nav-menu.active {
    left: 0;
    transition: 350ms;
}

.nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
}

.font-16 {
    font-size: 16px;
}

.font-12 {
    font-size: 12px;
}

.nav-text a {
    text-decoration: none;
    color: var(--white);
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
}
.nav-text a:hover {
    background-color: var(--navHover);
}

.nav-menu-items {
    width: 100%;
    margin-left: -100px;
    margin-top: 0px;
}

.navbar-toggle {
    background-color: var(--navy);
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
}
.nav-title {
    margin-left: 16px;
}

/*---------------------------------------*/
/*-------------Placeholder---------------*/
/*---------------------------------------*/

.Placeholder img {
    max-width: 500px;
    width: 100%;
    height: auto;
}

/*---------------------------------------*/
/*-------------Project Cards-------------*/
/*---------------------------------------*/
.projectCard {
    margin: 5px;
    border: 1px solid var(--lightGrey);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: var(--lightShadow);
    width: 301.6px;
    float: left;
}

.projectCardContainer {
    display: flex;
    justify-content: space-evenly;
}

div.gallery {
    margin: 5px;
    border: 1px solid var(--lightGrey);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 301.6px;
    float: left;
}

div.gallery img {
    width: 300px;
    height: 200px;
}

div.desc {
    text-align: center;
}

.projectImage {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transition: 0.3s;
    width: 300px;
    height: 200px;
}

.projectCard:hover {
    opacity: 0.7;
    cursor: pointer;
}

/* Caption of Modal Image */
#caption {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    text-align: center;
    color: var(--lightGrey);
    padding: 10px 0;
    height: 350px;
}

/* Add Animation */
#caption {
    animation-name: zoom;
    animation-duration: 0.6s;
}

@keyframes zoom {
    from {
        transform: scale(0.1);
    }

    to {
        transform: scale(1);
    }
}
/*---------------------------------------*/
/*----------MEET THE BOARD CARDS---------*/
/*---------------------------------------*/

.boardMemberCardContainer {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.boardMemberCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: var(--lightShadow);
    width: 375px;
    height: 450px;
    margin: 3%;
    text-align: center;
    border-radius: 15px;
}

.boardMemberPortrait {
    max-width: 100%;
    height: 300px;
    border-radius: 10px;
    box-shadow: var(--reallyLightShadow);
}

.boardMemberTitleContainer {
    margin-top: 10px;
}

.boardMemberTitle {
    margin-top: 0px;
    margin-bottom: 0px;
}

/*---------------------------------------*/
/*----------MEET THE TEAM CARDS----------*/
/*---------------------------------------*/

.MeetTheTeam {
    margin-top: 50px;
}
.container {
    padding: 0 16px;
}

.leaderCardInfo {
    padding: 0 16px;
    float: left;
    text-align: center;
}

.leaderCardDesc {
    padding-left: 16px;
    text-align: left;
    display: inline-block;
}

#leadership,
#teamLeads,
#otherMembers {
    padding-bottom: 75px;
}

.cardTitle {
    color: var(--navy);
    font-size: 18px;
}

.leaderDesc {
    margin: 0;
}

.leaderCard {
    box-shadow: var(--heavyShadow);
    border-radius: 15px;
    width: 600px;
    height: 475px;
    padding: 20px 20px 0px 20px;
    margin-top: 20px;
    columns: 236px 2;
}

.leaderCardContainer {
    display: grid;
    justify-content: space-evenly;
}
.leaderPortraitContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 260px;
    height: 300px;
}
.leaderPortrait {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    box-shadow: var(--reallyLightShadow);
}

/*Team lead cards*/
.teamLeadCard {
    box-shadow: var(--heavyShadow);
    border-radius: 15px;
    width: 300px;
    height: 160px;
    margin: 3%;
    text-align: center;
    padding: 10px 20px 0px 20px;
}

.teamLeadCardContainer {
    display: grid;
    justify-content: space-evenly;
    margin-left: -20px;
    margin-right: -20px;
}

/*Member cards*/
.memberCard {
    box-shadow: var(--lightShadow);
    width: 300px;
    height: 100px;
    margin: 3%;
    text-align: center;
    padding: 5px 20px 0px 20px;
}

.memberCardContainer {
    display: grid;
    justify-content: space-evenly;
    margin-left: -20px;
    margin-right: -20px;
}

/*---------------------------------------*/
/*------------BATTLEBOT CARDS------------*/
/*---------------------------------------*/
.battlebotCard {
    box-shadow: var(--lightShadow);
    background-color: var(--background-color);
    width: 300px;
    height: 125px;
    border-radius: 10px;
    padding: 5px 20px 0px 20px;
    margin: 3%;
    text-align: center;
}

.battlebotCardContainer {
    display: grid;
    justify-content: space-evenly;
    grid-template-columns: auto auto auto;
    top: 70px;
    height: var(--modalHeight);
}

.battlebotSeasonCard {
    box-shadow: var(--lightShadow);
    width: 150px;
    height: 100px;
    border-radius: 10px;
    float: center;
    margin: 3%;
    text-align: center;
    padding: 10px 40px 0px 40px;
    display: grid;
    justify-content: space-evenly;
}

.battlebotSeasonCard:hover {
    opacity: 0.7;
    cursor: pointer;
}

.battlebotSeasonCardContainer {
    display: flex;
    grid-template-columns: auto;
    justify-content: space-evenly;
    margin-left: -20px;
    margin-right: -20px;
}

.battlebotNextSeasonCard {
    box-shadow: var(--lightShadow);
    width: 350px;
    height: 110px;
    float: center;
    margin: 3%;
    text-align: center;
    padding: 10px 40px 0px 40px;
}

.battlebotThisSeasonCardContainer {
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: space-evenly;
    margin-left: -20%;
    margin-right: -20%;
}

#prevSemesters {
    padding-bottom: 100px;
    margin-left: 20%;
    margin-right: 20%;
}

#thisSemester {
    border-radius: 10px;
    padding-top: 50px;
    padding-bottom: 10px;
    margin-left: 20%;
    margin-right: 20%;
}

#nextSemesterBlurb {
    float: center;
}

/*---------------------------------------*/
/*-------------MEDIA QUERIES-------------*/
/*---------------------------------------*/
@media only screen and (min-width: 1800px) {
    .leaderCardContainer {
        grid-template-columns: auto auto auto;
    }
    #President {
        grid-area: 1/1/1/1;
    }
    #VicePresident {
        grid-area: 1/2/1/2;
    }
    #Treasurer {
        grid-area: 1/3/1/3;
    }
    #CurriculumPlanner {
        grid-area: 2/1/2/1;
    }
    #InternalCommunicationsCoordinator {
        grid-area: 2/2/2/2;
    }
    #ExternalCommunicationsCoordinator {
        grid-area: 2/3/2/3;
    }
}

@media only screen and (min-width: 1200px) {
    .projectCardContainer,
    /*.teamLeadCardContainer,       Only 3 teams right now*/
    .memberCardContainer,
    .battlebotCardContainer {
        grid-template-columns: auto auto auto;
    }
    .teamLeadCardContainer {
        grid-template-columns: auto auto auto;
    }
    .leaderCardContainer {
        grid-template-columns: auto auto;
    }
    #President {
        grid-area: 1/1/1/1;
    }
    #VicePresident {
        grid-area: 1/2/1/2;
    }
    #Treasurer {
        grid-area: 2/1/2/1;
    }
    #CurriculumPlanner {
        grid-area: 2/2/2/2;
    }
    #InternalCommunicationsCoordinator {
        grid-area: 3/1/3/1;
    }
    #ExternalCommunicationsCoordinator {
        grid-area: 3/2/3/2;
    }
}
@media only screen and (max-width: 1200px) {
    .projectCardContainer,
    .teamLeadCardContainer,
    .memberCardContainer,
    .battlebotCardContainer {
        grid-template-columns: auto auto auto;
    }
    .leaderCardContainer {
        grid-template-columns: auto;
    }
    #prevSemesters {
        margin-left: 10%;
        margin-right: 10%;
    }
    #nextSemesters {
        border-radius: 10px;
        margin-left: 10%;
        margin-right: 10%;
    }
    #President {
        grid-area: 1/1/1/1;
    }
    #VicePresident {
        grid-area: 2/1/2/1;
    }
    #Treasurer {
        grid-area: 3/1/3/1;
    }
    #CurriculumPlanner {
        grid-area: 4/1/4/1;
    }
    #InternalCommunicationsCoordinator {
        grid-area: 5/1/5/1;
    }
    #ExternalCommunicationsCoordinator {
        grid-area: 6/1/6/1;
    }
}
@media only screen and (max-width: 1050px) {
    #AboutUs {
        width: 100%;
    }
    #carousel-button {
        display: none;
    }
}
@media only screen and (max-width: 900px) {
    .projectCardContainer,
    .teamLeadCardContainer,
    .memberCardContainer,
    .battlebotCardContainer {
        grid-template-columns: auto auto;
    }
}
@media only screen and (max-width: 800px) {
    #prevSemesters {
        margin-left: 5%;
        margin-right: 5%;
    }
    #nextSemesters {
        margin-left: 5%;
        margin-right: 5%;
    }
    .sponsorimgs {
        flex-direction: column;
    }
}
@media only screen and (max-width: 700px) {
    .battlebotSeasonCardContainer {
        grid-template-columns: auto auto;
    }
    .battlebotThisSeasonCardContainer {
        grid-template-columns: auto auto;
    }

    .battlebotCardContainer {
        grid-template-columns: auto;
    }
    #prevSemesters {
        margin-left: 20%;
        margin-right: 20%;
    }
    #nextSemesters {
        margin-left: 20%;
        margin-right: 20%;
    }
    #carouselIMG {
        max-width: 100%;
        height: auto;
    }
    .leaderCard {
        box-shadow: var(--heavyShadow);
        width: 100%;
        height: auto;
        align-items: center;
        justify-content: center;
        padding-bottom: 20px;
    }

    .leaderCardContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 100%;
    }
}
@media only screen and (max-width: 600px) {
    .projectCardContainer,
    .memberCardContainer,
    .battlebotCardContainer {
        grid-template-columns: auto;
    }
    .teamLeadCardContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    #prevSemesters {
        margin-left: 10%;
        margin-right: 10%;
    }
    #nextSemesters {
        margin-left: 10%;
        margin-right: 10%;
    }
    #DiscordWidget {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .leaderCard {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
    .leaderCardInfo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
@media only screen and (max-width: 400px) {
    .battlebotSeasonCardContainer {
        grid-template-columns: auto;
    }
    .battlebotThisSeasonCardContainer {
        grid-template-columns: auto;
    }
    #prevSemesters {
        margin-left: 20%;
        margin-right: 20%;
    }
    #nextSemesters {
        margin-left: 20%;
        margin-right: 20%;
    }
}

.clipping-container {
    position: relative;
    border: 1px solid #aaa;
    margin-bottom: 12px;
    padding: 12px;
    width: 100%;
    overflow: hidden;
}

.modal {
    justify-content: space-evenly;
    align-items: center;
    box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;
    background-color: var(--white);
    border: 2px solid var(--white);
    border-radius: 12px;
    position: fixed;
    --modalWidth: 80%;
    width: var(--modalWidth);
    --modalHeight: 80%;
    height: var(--modalHeight);
    top: 70px;
    bottom: 300px;
    left: calc(50% - var(--modalWidth) / 2);
}

.popup {
    justify-content: space-evenly;
    align-items: center;
    box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;
    background-color: var(--white);
    border: 2px solid var(--white);
    border-radius: 12px;
    position: fixed;
    --modalWidth: 80%;
    width: var(--modalWidth);
    
    top: 70px;
    bottom: 100px;
    left: calc(50% - var(--modalWidth) / 2);
}

.closeButton {
    color: var(--navy);
    cursor: pointer;
    width: 30px;
    height: 30px;
    margin: 10px;
    float: right;
}
